import React from 'react';
import './Gracias.css';
import Banner from './componentes/banner/Banner';
import Planos from './componentes/planos/Planos';
import Footer from './componentes/footer/Footer';

function Gracias() {
  return (
    <div className="App">
      <Banner />
      <Planos />
      <Footer />
     </div>
  );
}

export default Gracias;