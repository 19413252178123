import React from 'react';
import { useHistory } from "react-router-dom";
import './Header.css';
import { Container, Row, Col } from 'react-bootstrap';
import $ from 'jquery';

function Header() {
  let history = useHistory();

  /*const showPop = () =>{
    $("#popup-camp").css("display","block");                
  }*/

  const hidepop = () =>{
    $("#popup-camp").css("display","none");
  } 


  const handleFormSubmit = event => {
    event.preventDefault();
    $('#msg').html("");
    if($('#nombre').val().length === 0){
      $('#msg').html("<span class='fore-white bold medium'>Ingresa tu nombre.</span>");
      return false;
   }
   if($('#email').val().length === 0){
    $('#msg').html("<span class='fore-white bold medium'>Ingresa tu correo para poder contactarte.</span>");
    return false;
}else{
  let regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if(!regex.test($('#email').val())){
    $('#msg').html("<span class='fore-white bold medium'>Revisa tu correo para poder contactarte.</span>");
    return false;
  }
}
    if($('#celular').val().length === 0){
        $('#msg').html("<span class='fore-white bold medium'>Ingresa tu celular.</span>");
        return false;
    }
    

    if($('#mensaje').val().length === 0){
      $('#msg').html("<span class='fore-white bold medium'>Ingresa tu mensaje.</span>");
      return false;
  }
    // Test
    $('#btn-send').attr("disabled", "true");
    $('#msg').html("<span class='fore-white bold medium'>Enviando...</span>");
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute("6LdomOYUAAAAAPFcgGufsFhhpCLOJOOCf2y5wTSw", { action: "homepage" })
        .then(token => {
          document.getElementById('token').value = token
          
          // Send the form with AJAX
          $.ajax({
            data: $('form').serialize(),
            type: 'POST',
            url: process.env.PUBLIC_URL + '/enviar.php',
            success: function (data) {
              $('#btn-send').attr("disabled", "false");
              if (data === 1) {
                //showPop();
                setTimeout(function(){
                  history.push("/gracias");
                }, 5000);
              } else {
                if (data === 0) {
                  $('#msg').html("<span class='fore-white bold medium'>Error al enviar</span>");
                } else {
                  if (data === 3) {
                    $('#msg').html("<span class='fore-white bold medium'>No pasaste nuestro control anti-spam, revisa los datos</span>");
                  } else {
                    $('#msg').html("<span class='fore-white bold medium'>Error, revisa por favor los campos</span>");
                  }
                }

              }

            },
            error: function (xhr, status, err) {
              console.error(status, err.toString())
            }
          })
        
        
        })
      });
     
      // Reset state after sending the form

  }

  const getPrice = () => {

    $.ajax({
      data: { meta_key: 'valventus_precio' },
      type: 'POST',
      url: 'https://constructoravalderrama.com/service/',
      success: function (data) {
        try {
          let datos = JSON.parse(data);
          if (datos.resp) {
            $("#precioGet").html(`$${datos.datos.meta_value}`);
          }
        } catch (error) {
          console.log(error);
        }

      },
      error: function (xhr, status, err) {
        console.error(status, err.toString())
      }
    })
  }

  getPrice();

  return (
    <header className="banner">
      <Container fluid={true} className="padding0">
        <Row>
          <Col md={1} className="padding0 hidden-sm"></Col>
          <Col md="10" sm={12} className="border-golden-right padding0">
            <Row>
              <Col lg={4} md={4} sm={3}></Col>
              <Col lg={4} md={4} sm={6} xs={12} className="padding0">
                <div className="float-form">
                  <span className="fore-pink titulo-form">¡TOMA UNA</span>
                  <span className="fore-pink titulo-form">ASESORÍA!</span>
                  <form>
                    <input type="hidden" id="token" name="token" value="" />
                    <input type="hidden" name="action" value="homepage" />
                    <input type="text" placeholder="Nombre*" id="nombre"  name="nombre" required/>
                    <input type="email" placeholder="Email*" id="email" name="email" required/>
                    <input type="text" placeholder="Celular*" id="celular" name="celular" required/>
                    <textarea placeholder="Mensaje*" name="mensaje" id="mensaje" required></textarea>
                    <div id="msg"></div>
                    <button onClick={handleFormSubmit} >ENVIAR</button>
                  </form>
                </div>
              </Col>
            </Row>
            <span className="title-bottom  bold fore-yellow">APARTAMENTOS DESDE</span>
            <span className="title-bottom bold big fore-white"> <span id="precioGet">$</span> MILLONES</span>
            <h4 className="text-center fore-white">FLORIDABLANCA - GIRÓN</h4>
          </Col>
          <Col md={1} className="border-golden-top padding0"></Col>
        </Row>
      </Container>
      <div id="popup-camp" onClick={hidepop} ><img src="https://constructoravalderrama.com/wp-content/uploads/2020/10/BONO-ANIMADO-VALVENTUS.gif" alt="Bono" /><div id="close-btn" onClick={hidepop} >Cerrar</div></div>
    </header>
  );
}

export default Header;
