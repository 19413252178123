import React from 'react';
import './Basic.css';
import elegancia from '../../assets/img/render.png'
import leafRight from '../../assets/img/right-leaf.png'
import leafLeft from '../../assets/img/left-leaf.png'
import maps from '../../assets/img/maps.png'
import { Container, Row, Col } from 'react-bootstrap';

function Basic() {
  return (
    <Container fluid className="relative">
      <img src={leafLeft} className="leafLeft" alt="hojaizquierda" />
      <Container className="section-estandar">

        <Row>
          <Col md="5" className="text-justify fore-gray mtop40">
            <p><b>VALVENTUS CONDOMINIO</b> es un proyecto diseñado para quienes conocen el valor de la tranquilidad y buscan disfrutar de la exclusividad</p>

            <p>En cada una de sus etapas, espacios, acabados y detalles están impresos los beneficios de vivir en el pulmón de la ciudad rodeado de ambientes naturales y al mismo tiempo en el centro de una zona comercial y empresarial en desarrollo</p>

            <Row className="circles">
              <Col xs={2}></Col>
              <Col xs={2}>
                <div className="circle-blue"></div>
              </Col>
              <Col xs={2}></Col>
              <Col xs={2}>
              <div className="circle-blue"></div>
              </Col>
              <Col xs={2}></Col>
              <Col xs={2}>
              <div className="circle-blue"></div>
              </Col>
            </Row>
            <Row>
            <a href="https://www.google.com.co/maps/search/m+2.0+Anillo+vial,+v%C3%ADa+Floridablanca+-+Gir%C3%B3n/@7.0613639,-73.1124122,19z?hl=es-419" target="_blank" rel="noopener noreferrer" className="img-maps"><img src={maps} alt="Conoce la zona" /></a>
            </Row>
          </Col>
          <Col md="7">
            <img src={elegancia} className="img-elegancia" alt="Elegancia y Exclusividad" />
          </Col>
        </Row>

      </Container>

      <img src={leafRight} className="leafRight" alt="hojaderecha" />

    </Container>
  );
}

export default Basic;
