import React from 'react';
import './Planos.css';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import leafLeft from '../../assets/img/left-leaf.png';
import sombra from '../../assets/img/sombra.png';

function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../../assets/img/plano/', false, /\.(png|jpe?g|svg)$/));

const Proyecto = (props) => {

    return (
        <Container fluid className="relative ptop60 padding0">
            <Tab.Container id="planos-tab" defaultActiveKey="a">
            <img src={leafLeft} className="leafLeft turndown" alt="hojaizquierda" />
            <Container className="mtop40">
                <Row className="amenidades">
                
                    <Col md={12}>
                        <h2 className="title-seccion">Tipos de Áreas:</h2>
                        <Row className="mtop20">
                            <Col xs={12}>
                                <Nav>
                                    <Nav.Item>
                                        <Nav.Link eventKey="a">TIPO A</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="b">TIPO B</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="c">TIPO C</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <img src={sombra} alt="Sombra" className="img-sombra" />
                            </Col>
                        </Row>
                    </Col>
                  
                </Row>
            </Container>
            <Row className="margin0 padding0">
                <Col xs={12} className="padding0">
                    <Tab.Content>
                        <Tab.Pane eventKey="a">
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[0]} alt="Tipo A" className="img-plano hidden-sm" />
                                    <img src={images[4]} alt="Tipo A" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="b">
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[1]} alt="Tipo B" className="img-plano hidden-sm" />
                                    <img src={images[5]} alt="Tipo B" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="c" >
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[2]} alt="Tipo C" className="img-plano hidden-sm" />
                                    <img src={images[6]} alt="Tipo C" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="d">
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[3]} alt="Tipo D" className="img-plano hidden-sm" />
                                    <img src={images[7]} alt="Tipo D" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="e">
                            <Row>
                                <Col sm="12"  className="padding0 pleft">
                                    <img src={images[8]} alt="Tipo E" className="img-plano hidden-sm" />
                                    <img src={images[9]} alt="Tipo E" className="img-plano hidden-md" />
                                </Col>
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
            </Tab.Container>
            <Container>
                <Row className="mtop20">
                    <Col xs={12} className="disclaimer">
                        * Las imágenes y contenidos aquí publicados pueden no coincidir en su totalidad con la realidad de la construcción. Los materiales texturas, colores, equipamiento y mobiliario que se exhibe es solamente ilustrativo y para efecto de pre-ventas. Este material está destinado exclusivamente para la información y/o consulta de los potenciales compradores. Los cambios que el proyecto pueda tener son exclusividad de la constructora sin tener que dar previo aviso, siempre y cuando estos no afecten la calidad del producto final.
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Proyecto;
