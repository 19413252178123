import React, { useEffect } from 'react';

function Captcha() {

const handleLoaded = _ => {
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute("6LdomOYUAAAAAPFcgGufsFhhpCLOJOOCf2y5wTSw", { action: "homepage" })
        .then(token => {
            document.getElementById('token').value = token
        })
    })
  }
  
  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=6LdomOYUAAAAAPFcgGufsFhhpCLOJOOCf2y5wTSw"
    script.addEventListener("load", handleLoaded)
    document.body.appendChild(script)
  }, [])
  
  return (
    <div
      className="g-recaptcha"
      data-sitekey="6LdomOYUAAAAAPFcgGufsFhhpCLOJOOCf2y5wTSw"
      data-size="invisible"
    ></div>
  )

}

export default Captcha;