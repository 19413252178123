import React from 'react';
import './Proyecto.css';
import edificio from '../../assets/img/edificio.png'
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import logo from '../../assets/img/logo.png';
import flor from '../../assets/img/title-flower.png';


function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../../assets/img/slider/', false, /\.(png|jpe?g|svg)$/));
const icons = importAll(require.context('../../assets/img/icons/', false, /\.(png|jpe?g|svg)$/));

const Proyecto = (props) => {

    

  return (
    <Container fluid className="relative back-leaf">
      <Container className="section-estandar">
        <Row className="amenidades">
            <Col md={12}>
                <h2 className="title-seccion">Amenidades</h2>
            </Col>
            <Col md={6}>
            <Carousel controls={false}>
                
            { images.map((image, indx) => {
                            return (
                                <Carousel.Item key={indx} ><img  className="d-block w-100" src={image} alt="Valventus" /></Carousel.Item>
                            )
                        })}
            </Carousel>
            </Col>
            <Col md={6}>
                <Row className="icons">
                    <div className="col-sm-2p5">
                        <img
                            className="d-block w-100"
                            src={icons[0]}
                            alt="Valventus"
                        />
                    </div>
                    <div className="col-sm-2p5">
                        <img
                            className="d-block w-100"
                            src={icons[1]}
                            alt="Valventus"
                        />
                    </div>
                    <div className="col-sm-2p5">
                        <img
                            className="d-block w-100"
                            src={icons[2]}
                            alt="Valventus"
                        />
                    </div>
                    <div className="col-sm-2p5">
                        <img
                            className="d-block w-100"
                            src={icons[3]}
                            alt="Valventus"
                        />
                    </div>
                    <div className="col-sm-2p5">
                        <img
                            className="d-block w-100"
                            src={icons[4]}
                            alt="Valventus"
                        />
                    </div>
                    <div className="col-sm-2p5 offset-2p5">
                        <img
                            className="d-block w-100"
                            src={icons[5]}
                            alt="Valventus"
                        />
                    </div>
                    <div className="col-sm-2p5">
                        <img
                            className="d-block w-100"
                            src={icons[6]}
                            alt="Valventus"
                        />
                    </div>
                    <div className="col-sm-2p5">
                        <img
                            className="d-block w-100"
                            src={icons[7]}
                            alt="Valventus"
                        />
                    </div>
                    <div className="col-sm-2p5">
                        <img
                            className="d-block w-100"
                            src={icons[8]}
                            alt="Valventus"
                        />
                    </div>
                    </Row>          
            </Col>
        </Row>
      </Container>
      <Container >
          <Row>
                <Col md={4} sm={6} xs={12} className="implantacion">
                    <h3 className="thin">IMPLANTACIÓN<br/><span className="bold">URBANÍSTICA</span></h3>
                    <br/>
                    <h5>ETAPA<span className="bigBlue">1</span></h5>
                    <ul className="punto">
                        <li>TORRE UNO</li>
                        <li>LOBBY</li>
                        <li><b>CINEMA</b> SKY</li>
                        <li><b>TERRAZA BAR</b> B.B.Q</li>
                        <li>CANCHA <b>SINTÉTICA</b></li>
                        <li>CANCHA <b>SQUASH</b></li>
                        <li>ZONA <b>MULTIJUEGOS</b>
                            <ul className="guion">
                                <li>TENIS DE MESA, POOL</li>
                            </ul>
                        </li>
                        <li>1<sup>RA</sup> ETAPA DE <b>CICLORUTA</b></li>
                    </ul>
                    <br/>
                    <h5>ETAPA<span className="bigBlue">2</span></h5>
                    <ul className="punto">
                        <li>TORRE UNO
                            <ul className="guion">
                                <li>SOLÁRIUM, ZONA DE <b>PLAYA</b></li>
                            </ul>
                        </li>
                        <li><b>PISCNA</b> NIÑOS</li>
                        <li>2<sup>DA</sup> ETAPA DE <b>CICLORUTA</b></li>
                        <li><b>MINI GOLF</b></li>
                        <li>SALÓN <b>SOCIAL</b></li>
                        <li><b>GIMNASIO</b> MULTIFUNCIONAL</li>
                    </ul>
                    <br/>
                    <img src={logo} className="w-60" alt="Logo Valventus" />
                </Col>
                <Col md={8} sm={6} xs={12}>
                    <img src={flor} className="flores-top" alt="Símbolo Valventus" />
                    <img src={edificio} className="edificio" alt="Edificio Valventus" />
                </Col>
          </Row>
      </Container>
      <img src={flor} className="flores" alt="Símbolo Valventus" />
    </Container>
  );
}

export default Proyecto;
